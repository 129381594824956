/*
 *	-- Libraries and Plugins --
 *	Scripts concatenated and placed into document <head>
 */


// import('dependencies/framework/console-fix.js');
// import('dependencies/framework/modernizr-3.0.0-custom.min.js');
// import('dependencies/libraries/jquery-2.2.0.js');
// import('dependencies/libraries/snap.svg-min.js');
// import('dependencies/plugins/jquery.debouncedresize.js');
